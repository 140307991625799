<template>
  <v-card elevation="3" style="width: 100%; min-height: 500px;">

    <v-row no-gutters class="mx-2">

      <!-- Button -->
      <v-col cols="12">
        <v-row justify="end" no-gutters>
          <v-btn
              class="btn-primary"
              color="primary"
              @click="setShowUserAddDialog(true)">
            {{ $t('accounts.users.buttons.add') }}
          </v-btn>
        </v-row>
      </v-col>

      <!-- Datatable -->
      <v-col cols="12" class="mt-3">
        <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalItems"
            :options.sync="pagination"
            :loading="loading"
            :items-per-page="20"
            sort-by="createdAt"
            :sort-desc="false"
            :footer-props="{'items-per-page-options': itemsPerPage}"
            class="elevation-1">

          <!-- Email -->
          <template v-slot:item.email="{item}">
            <span
                class="btn-link"
                @click="setShowUserDetailsDialog(true, item)">
              {{ item.email }}
            </span>
          </template>

          <!-- Last login -->
          <template v-slot:item.last_login="{item}">
            <span v-if="item.last_login">{{
                $t('accounts.users.labels.last_login', {
                  date: formatDatetime(item.last_login)
                })
              }}</span>
            <span v-else>{{ $t('accounts.users.labels.never_connected') }}</span>
          </template>

          <!-- Last password update --
          <template v-slot:item.last_password_update="{item}">
            <span v-if="item.last_password_modification">{{
                $t('accounts.users.labels.last_password_update', {
                  date: formatDatetime(item.last_password_update)
                })
              }}</span>
            <span v-else>{{ $t('accounts.users.labels.password_never_updated') }}</span>
          </template-->

          <!-- Enabled -->
          <template v-slot:item.enabled="{item}">
            <v-switch readonly v-model="item.enabled"/>
          </template>

          <!-- Actions -->
          <template v-slot:item.action="{item}">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="setShowUserChangePasswordDialog(true, item)" v-bind="attrs" v-on="on">
                  <v-icon small>fas fa-key</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('accounts.users.buttons.resetPassword') }}</span>
            </v-tooltip>
            <v-btn icon @click="setShowUserEditDialog(true, item)">
              <v-icon small>fas fa-pencil</v-icon>
            </v-btn>
            <v-btn icon @click="setShowUserDeleteDialog(true, item)">
              <v-icon small>fas fa-trash</v-icon>
            </v-btn>
          </template>

        </v-data-table>
      </v-col>
    </v-row>

    <!-- User details dialog -->
    <AccountUserDetailsDialog
      v-if="showDialog.details"
      :value="showDialog.details"
      :user="currentUser"
      @close="setShowUserDetailsDialog(false)"
    />

    <!-- User add dialog -->
    <AccountUserAddDialog
      v-if="showDialog.add"
      :value="showDialog.add"
      :item="null"
      :account-id="account.id"
      :account-type="account.type"
      @close="(needRefresh) => setShowUserAddDialog(false, needRefresh)"
    />

    <!-- User edit dialog -->
    <AccountUserEditDialog
      v-if="showDialog.edit"
      :value="showDialog.edit"
      :item="currentUser"
      @close="(needRefresh) => setShowUserEditDialog(false, null, needRefresh)"
    />

    <!-- User delete dialog -->
    <DeleteDialog
      v-if="showDialog.delete"
      :value="showDialog.delete"
      :title="$t('accounts.users.deleteDialog.title')"
      @submit="deleteUser"
      @close="(needRefresh) => setShowUserDeleteDialog(false, null, needRefresh)"
    />

    <!-- User change password -->
    <AccountUserChangePasswordDialog
      v-if="showDialog.changePassword"
      :value="showDialog.changePassword"
      :user-id="currentUser.id"
      @close="(needRefresh) => setShowUserChangePasswordDialog(false, null, needRefresh)"
    />
  </v-card>
</template>

<script>
import vuetifyDatatable            from '@/mixins/vuetifyDataTable';
import formatter                   from "@/mixins/formatter";

export default {

  name: "AccountUsersTab",
  components: {
    AccountUserDetailsDialog: () => import("@/components/Accounts/Users/AccountUserDetailsDialog"),
    AccountUserAddDialog: () => import("@/components/Accounts/Users/AccountUserAddDialog"),
    AccountUserEditDialog: () => import("@/components/Accounts/Users/AccountUserEditDialog"),
    AccountUserChangePasswordDialog: () => import("@/components/Accounts/Users/AccountUserChangePasswordDialog"),
    DeleteDialog: () => import("@/components/Common/DeleteDialog")
  },

  props: {
    account: {type: Object, default: () => null}
  },

  mixins: [vuetifyDatatable, formatter],

  data() {
    return {
      isLoaded: false,
      currentUser: null,

      showDialog: {
        add: false,
        details: false,
        edit: false,
        delete: false,
        changePassword: false
      },

      headers: [
        {
          text: this.$t('accounts.users.datatable.headers.email'),
          value: "email",
          sortable: false,
          width: "30%"
        },
        {
          text: this.$t('accounts.users.datatable.headers.last_login'),
          value: "last_login",
          sortable: false,
          width: "20%"
        },
        {
          text: this.$t('accounts.users.datatable.headers.enabled'),
          value: "enabled",
          sortable: false,
          width: "15%"
        },
        {
          text: "",
          value: "action",
          sortable: false,
          width: "10%"
        }
      ],

      URL_API: {
        GET_ALL: "/users"
      },

      filters: {
        accounts: [this.account.id]
      }
    }
  },

  methods: {

    deleteUser() {
      this.$http
          .delete(`/users/${this.currentUser.id}`, {
            headers: {Authorization: "Bearer " + this.$session.get('jwt')}
          })
          .then(res => {
            this.$store.commit("toast/showSuccess", this.$t('accounts.users.deleteDialog.success'));
            this.setShowUserDeleteDialog(false, null, true);
          })
          .catch(err => {
            this.$store.commit("alert/showErrorAxios", err);
          })
          .finally(() => {
            this.isLoadingBtnSave = false;
          });
    },

    setShowUserDetailsDialog(val, user = null) {
      this.currentUser = user;
      this.showDialog.details = val;
    },

    setShowUserAddDialog(val, reloadData = false) {
      if (reloadData)
        this.searchTrigger();

      this.showDialog.add = val;
    },

    setShowUserEditDialog(val, user = null, reloadData = false) {
      this.currentUser = user;
      if (reloadData)
        this.searchTrigger();

      this.showDialog.edit = val;
    },

    setShowUserDeleteDialog(val, user = null, reloadData = false) {
      this.currentUser = user;
      if (reloadData)
        this.searchTrigger();

      this.showDialog.delete = val;
    },

    setShowUserChangePasswordDialog(val, user = null, reloadData = false) {
      this.currentUser = user;
      if (reloadData)
        this.searchTrigger();
      this.showDialog.changePassword = val;
    }
  }
}
</script>

<style scoped>

</style>
